import React from 'react';
import PropTypes from 'prop-types';
import HTMLReactParser from 'html-react-parser';
import styles from './PageIntro.module.scss';

const PageIntro = ({ title, intro }) => (
  <div className={styles.container}>
    {title && <h1 className={styles.title}>{title}</h1>}
    {intro && <p className={styles.intro}>{HTMLReactParser(intro)}</p>}
  </div>
);

export default PageIntro;

PageIntro.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
};
